import React, { useEffect, useContext, useRef } from "react";
import { GlobalStateContext } from "../../context/GlobalContextProvider";
// import { TweenLite, Power3 } from "gsap";

import Seo from "../../components/seo/seo";
import Layout from "../../components/layout/layout";
import PageBanner from "../../components/common/PageBanner";
import Clients from "../../components/common/Clients";
import InteractiveVideo from "../../components/common/InteractiveVideo";
// import image from "../../assets/img/products/Pre-job discussions.jpg";

import { StaticImage } from "gatsby-plugin-image";

const PreJobDiscussions = () => {
  const state = useContext(GlobalStateContext);
  let container = useRef(null);

  useEffect(() => {
    if (!state.showPageLoading) {
      const cn = container.current;
      setTimeout(() => {
        cn.classList.remove("hide-container");
      }, state.containerAnimationDelay * 1000);
    }
  }, [state.showPageLoading, state.containerAnimationDelay]);

  return (
    <Layout>
      <Seo
        title='Pre-job Discussions | VIRSAT - Virtual Reality Safety Training'
        description='Just before work starts, pre-job discussions are often held. The effectiveness can be compromised due to language problems, noisy environment, one-way.'
      />
      <PageBanner title='Pre-job discussions' subtitle='Create discussions' />
      <section
        className='main-container single-product hide-container'
        ref={container}
      >
        <div className='row top-pad bottom-pad fd-col less-mar'>
          <div className='column'>
            <p className='medium reduce_width dark no-mar center'>
              Just before work starts, pre-job discussions are often held. The
              effectiveness can be compromised due to language problems, noisy
              environment, one-way communication, etc.
            </p>
            <hr className='site-border' />
          </div>
          <div className='column details-container'>
            <div className='details'>
              <p>
                A series of animations focused on the job at hand and its risks
                is available. Watching the animations initiates discussions on
                how to do the work safe.
              </p>
            </div>
            <div className='image-container'>
              <StaticImage
                src='../../assets/img/products/Pre-job discussions.jpg'
                alt='Pre-job Discussions'
                placeholder='blurred'
                objectFit
              />
              {/* <img src={image} alt='Pre-job discussions' /> */}
            </div>
          </div>
        </div>
      </section>
      <Clients />
      <InteractiveVideo />
    </Layout>
  );
};

export default PreJobDiscussions;
